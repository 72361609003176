header {
    display: flex;
    position: fixed;
    width: 100%;
    justify-content: space-around;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
    color: white;
    padding: 1.5em 0;
    overflow: hidden;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.404);
    top: -100px;
    animation-name: drop-down;
    animation-duration: 1000ms;
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
}

.nav-icon {
    display: none;
    position: absolute;
    top: 0.55em;
    right: 1em;
    width: 40px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.nav-icon span  {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.nav-icon span:nth-child(1) {
    top: 0px;
  }
  
  .nav-icon span:nth-child(2) {
    top: 14px;
  }
  
  .nav-icon span:nth-child(3) {
    top: 28px;
  }
  
  .nav-icon.open span:nth-child(1) {
    top: 14px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
  .nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: 60px;
  }
  
  .nav-icon.open span:nth-child(3) {
    top: 14px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

@keyframes drop-down { 
    from {
        top: -100px;
    }
    
    to {
        top: 0px;
    }
}

@media screen and (max-width: 650px){
    header {
        flex-direction: column;
        padding: 4em 0;
        row-gap: 2em;
        align-items: center;
        font-size: 1.5em;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        top: -100px;
        height: 2.5em;
        padding: 0;
        transition: 500ms;
    }

    header.open {
        top: 0;
        height: 100vh;
    }

    .nav-icon {
        display: block;
    }

    header li {
        padding: 1em 0;
    }

    @keyframes drop-down { 
      from {
          top: -100px;
      }
      
      to {
          top: 0px;
      }
  }
}