.landingPage {
    height: 100vh;
    width: 100vw;
    position: relative;
    color: white;
    overflow: hidden;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 15px 15px black;
    filter: brightness(20%) blur(8px);
    -webkit-filter: brightness(20%) blur(8px);
}

.landingText {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1em;
    text-align: center;
    animation-name: fade-in;
    animation-duration: 3000ms;
    animation-delay: 500ms;
    opacity: 0;
    animation-fill-mode: forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}


.landingText p {
    max-width: 35em;
}

.logo {
    width: 250px;
    height: auto;
}

.landingPage button {
    margin-top: 2em;
}

.landingLinks {
    position: absolute;
    width: 90%;
    bottom: -100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 50%;
    transform: translate(-50%);
    padding: 1em 0;
    animation-name: come-up;
    animation-duration: 1000ms;
    animation-delay: 1000ms;
    animation-fill-mode: forwards;
}

@keyframes come-up {
    from {
        bottom: -100px;
    }

    to {
        bottom: 0px;
    }

}

.landingAddress {
    display: flex;
    gap: 1em;
}

.landingSocials {
    display: flex;
    gap: 2em;
}

.landingSocials a {
    height: 4em;
    width: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landingSocials a img {
    height: 65%;
    transition: 500ms;

}

.landingSocials a img:hover {
    height: 100%;
}

.landingAddress img {
    height: 2em;
}

@media screen and (max-width: 650px) {
    @keyframes come-up {
        from {
            bottom: -100px;
        }

        to {
            bottom: 50px;
        }
    }

    .landingText {
        position: absolute;
        left: 50%;
        top: 40%;
        width: 95%;
    }

    .landingLinks {
        width: 95%;
        justify-content: space-between;
        align-items: flex-end;
        gap: 2em;
    }

    .landingAddress {
        display: flex;
        gap: 0.4em;
        width: 13em;
        align-items: center;
    }
    
    .landingSocials {
        display: flex;
        gap: 1em;
        flex-direction: column;
    }

    .landingSocials a {
        height: 3em;
        width: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    
.landingSocials a img {
    height: 100%;
}


    
}