@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
:root {
  --red: #d00000;

}

.red {
  color: var(--red);
} 

* {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  -webkit-tap-highlight-color:transparent;
}

body{
  font-family: 'Roboto', sans-serif;
  line-height: 1.7;
}

button {
  padding: 1em 3em;
  background-color: rgba(255, 255, 255, 0);
  color: rgb(214, 214, 214);
  border: none;
  border: 1px solid var(--red);
  font-size: .8rem;
  font-weight: 700;
  letter-spacing: .05em;
  text-transform: uppercase;
  border-radius: 16px;
  transition: 300ms;
}

button:hover {
  cursor: pointer;
  padding: 1em 5em;
  background-color: var(--red);
  color: white;
}

.uppercase-text {
  text-transform:uppercase;
  letter-spacing: 2px;
  font-size: 0.8em;
}

.svg {
  filter: invert(100%) sepia(100%) saturate(4%) hue-rotate(79deg) brightness(106%) contrast(100%);
}

.svg.red{
  filter: invert(11%) sepia(72%) saturate(6159%) hue-rotate(360deg) brightness(98%) contrast(117%);
}


.underline-on-hover {
  display: inline-flex;
  align-items: center;
  height: auto;
  padding-bottom: 0.5em;
  position: relative;
}

.underline-on-hover:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  left: 0;
  bottom: 0em;
  transition: 0.5s;
  background-color: var(--red)
}

.underline-on-hover:hover:after {
  width: 100%
}

.underline-on-hover:hover {
  cursor: pointer;
}

footer {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.619);
  color: white;
  padding: 2em 0;
}