.booking .section-content {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 5em;
    text-align: center;
}

.booking-option {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
}

.booking-option h3 {
    font-size: large;
    font-weight: lighter;
}

.booking button {
    color: white;
    padding: 1em 5em;
    background-color: var(--red);
}

.booking button:hover {
    color: rgb(143, 143, 143);
    padding: 1em 3em;
    background-color: rgb(255, 255, 255);
    
}

@media screen and (max-width: 650px) {
    .booking .section-content {
        font-size: 1.2em;
        grid-template-columns: 1fr;
    }

    .booking-option {
        display: flex;
        flex-direction: column;
        row-gap: 1.5em;
    }
    
    
}